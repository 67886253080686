@for $i from 0 through 100 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }

  .lh-#{$i} {
    line-height: #{$i}px;
  }

  .rad-#{$i} {
    border-radius: #{$i}px;
  }

  .w-#{$i} {
    width: #{$i}px;
  }

  .h-#{$i} {
    height: #{$i}px;
  }

  .mg-#{$i} {
    margin: #{$i / 16}rem;
  }

  .mx-#{$i} {
    margin-left: #{$i / 16}rem;
    margin-right: #{$i / 16}rem;
  }

  .my-#{$i} {
    margin-top: #{$i / 16}rem;
    margin-bottom: #{$i / 16}rem;
  }

  .mt-#{$i} {
    margin-top: #{$i / 16}rem;
  }

  .mr-#{$i} {
    margin-right: #{$i / 16}rem;
  }

  .mb-#{$i} {
    margin-bottom: #{$i / 16}rem;
  }

  .ml-#{$i} {
    margin-left: #{$i / 16}rem;
  }

  .pg-#{$i} {
    padding: #{$i / 16}rem;
  }

  .px-#{$i} {
    padding-left: #{$i / 16}rem;
    padding-right: #{$i / 16}rem;
  }

  .py-#{$i} {
    padding-top: #{$i / 16}rem;
    padding-bottom: #{$i / 16}rem;
  }

  .pt-#{$i} {
    padding-top: #{$i / 16}rem;
  }

  .pr-#{$i} {
    padding-right: #{$i / 16}rem;
  }

  .pb-#{$i} {
    padding-bottom: #{$i / 16}rem;
  }

  .pl-#{$i} {
    padding-left: #{$i / 16}rem;
  }

  .maxwrem-#{$i} {
    max-width: #{$i}rem;
  }

  .maxw-#{$i} {
    max-width: #{$i}px;
  }

  .maxhrem-#{$i} {
    max-height: #{$i}rem;
  }

  .maxh-#{$i} {
    max-height: #{$i}px;
  }
}
