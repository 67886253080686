// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}
.pricing-table__title,
.pricing-table__price-val {
  color: red;
}
.pricing-table__title::before {
  background: red !important;
}
.pricing-table__price-val {
  border: 1px dotted red !important;
}

.text_empty {
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.textindiv2 {
  .withKey:nth-child(4n + 3) {
    .bg-primary {
      background-color: #5578eb !important;
    }
  }
  .withKey:nth-child(4n + 4) {
    .bg-primary {
      background-color: #fd397a !important;
    }
  }
  .withKey:nth-child(4n + 5) {
    .bg-primary {
      background-color: #ffb822 !important;
    }
  }
}
.MuiPaper-elevation1 {
  box-shadow: unset !important;
}
.card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  padding: 1rem 2.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #ebedf3;
  width: 100%;
  &:first-child {
    border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  }
  .title_product {
    width: 100%;
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0;

    .card-icon {
      margin-right: 0.75rem;
      line-height: 0;
    }
    .card-label {
      font-weight: 600;
      font-size: 1.275rem;
      margin-bottom: 0 !important;
      color: #181c32;
      font-family: Poppins, Helvetica, sans-serif;
    }
  }
}

.choose_content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0.42rem;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.position-relative {
  position: relative !important;
  height: 200px;
  margin-bottom: 3.25rem !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.svg.svg-fill-primary {
  fill: #3699ff !important;
}
.opacity-4 {
  opacity: 0.04 !important;
}
.position-absolute {
  position: absolute !important;
}
span.svg-icon.svg-icon-5x.svg-icon-primary {
  overflow: hidden;
  width: 125px;
  img {
    overflow: hidden;
    vertical-align: middle;
  }
}

.choose_product {
  width: 100%;
  background: #e8e8e89e;
  padding: 6% 0;

  .choose_content {
    max-width: 1220px;
    background: #fff;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 8px 31px 0 #d5d5d3, 0 16px 19px 0 #f2f2f0;

    .card-body {
      .list_product {
        justify-content: center;
        flex-wrap: wrap;
      }
      .thumbnail.product_item {
        &:last-child {
          border: none !important;
        }
        .caption.text-center {
          .btn_go {
            color: #fff;
            background-color: #3699ff;
            border-color: #3699ff;
            font-size: 1rem !important;
            margin: 20px 0;
            font-family: Poppins, Helvetica, sans-serif;
            border-radius: 6px;
            padding: 0.75rem 3.75rem;
            font-weight: 600;
            text-transform: uppercase;
            &:hover {
              color: #fff;
              background-color: #187de4;
              border-color: #187de4;
            }
          }
        }
      }
    }
  }
}
.landing_page {
  display: flex;
  flex-wrap: wrap;
  .upload_landing {
    width: 20%;
    border-right: 1px solid #fff5f5;
    padding: 0 15px;
    box-shadow: 0 8px 31px 0 #d5d5d3, 0 16px 19px 0 #fdfdfd;
  }
  .preview_landing {
    width: 80%;
    text-align: center;
    margin-top: 15px;
    .title_preview {
      width: 100%;
      border-bottom: 1px solid #e6e4e4;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 20px;
      font-weight: 500;
    }
    .page_preview {
      max-width: 960px;
      margin: 20px auto;
    }
  }
}
.input-domain {
  width: 100%;
  border: 1px solid #e2e2e2;
  height: 43px;
  border-radius: 4px;
  padding: 10px;
}
.input-domain.MuiInput-underline:after {
  border-bottom: unset !important;
}
.input-domain.MuiInput-underline:before,
.input-domain.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: unset !important;
}

@media only screen and (max-width: 1024px) {
  .kt-container {
    padding: 0 !important;
  }
  div#kt_header {
    position: fixed;
    z-index: 9;
    background: #fff;
    width: 100%;
    margin-top: 50px;
    display: flex;
  }
  .block-choose {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    order: 99;
    z-index: 9;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
    margin-left: 0 !important;
  }
  .kt-portlet {
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .card,
  .kt-section .kt-section__content {
    margin-top: 40px;
  }
  .landing_page {
    .upload_landing {
      width: 100%;
      margin-top: 30px;
      padding-bottom: 20px;
    }
    .preview_landing {
      width: 100%;
      text-align: center;
      margin-top: 15px;
      padding: 20px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .choose_product {
    .choose_content {
      .card-header {
      }
      .card-body {
        .list_product {
          justify-content: center;
          flex-wrap: wrap;
        }
        .thumbnail.product_item {
          width: 100%;
          margin-top: unset !important;
          border: none !important;
          border-bottom: 1px solid #c7c9d0 !important;
          padding: 30px 0 !important;
          &:last-child {
            border: none !important;
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .distributorTop {
    margin-left: 0px !important;
  }
  .withKey {
    width: 100%;
  }
  .up-level-contain {
    background-color: #ddd;
    border-radius: 20px;
    width: 67px;
    height: 14px;
  }
  .icon_header {
    width: 20px !important;
    height: 20px !important;
    margin-right: 0px;
  }
  .total_user {
    width: 37px !important;
  }
  span.anticon.anticon-dollar {
    padding: 2px !important;
  }
  .up-level-contain {
    height: 3px !important;
    margin: 3px 0px;
  }
  .up-level-progresswidth {
    width: 10.97%;
    height: 3px;
  }
  .icon_1 {
    width: 38px !important;
  }
  .kt-header__topbar-user {
    line-height: 1 !important;
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 450px) {
  .thumbnail.product_item {
    width: 100%;
  }
  .kt-portlet {
    margin-top: 90px;
  }
  .card {
    margin-top: 55px;
  }
  .kt-section .kt-section__content {
    margin-top: 55px;
  }
  .block-choose {
    text-align: center;
    // margin: 0 25px 10px 25px !important;
  }
  .landing_page .upload_landing {
    width: 100%;
    margin-top: 55px;
    padding-bottom: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .distributorTop {
    margin-left: 0px !important;
  }
  .withKey {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1220px) {
  .choose_product .choose_content {
    background: #fff;
    margin: 0 15px !important;
    max-width: unset !important;
    box-shadow: 0 8px 31px 0 #d5d5d3, 0 16px 19px 0 #f2f2f0;
  }
}
@media only screen and (min-width: 768px) {
  .choose_product {
    .choose_content {
      max-width: 1220px;

      .card-body {
        .thumbnail.product_item {
          width: 45%;
          padding: 0 2% !important;
          border: unset !important;
          padding: 30px !important;
          height: 100%;
          margin: 2px 2px 20px 2px;

          &:nth-child(2n + 1) {
            border-right: 1px solid #ebedf3 !important;
          }
          &:last-child {
            border-right: unset !important;
          }
          &:hover {
            box-shadow: 0 2px 3px 0 #d5d5d3, 0 3px 10px 0 #f2f2f0;
          }
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 920px) {
  .distributorTop {
    margin-left: 240px !important;
  }
  .withKey {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 920px) {
  .distributorTop {
    margin-left: 270px !important;
  }
  .withKey {
    width: 20%;
  }
}
@media only screen and (min-width: 1024px) {
  .choose_product {
    .choose_content {
      .card-body {
        .thumbnail.product_item {
          width: 30%;
          padding: 30px 2% !important;
          border: none;
          &:nth-child(3n + 1) {
            border-right: 1px solid #ebedf3 !important;
          }
          &:nth-child(3n + 3) {
            border-left: 1px solid #ebedf3 !important;
            border-right: unset !important;
          }
          &:nth-child(2n + 1) {
            border-right: unset;
          }
          .caption.text-center {
            h5.text-h5 {
              font-size: 19px !important;
              margin: 20px 0;
              font-family: Poppins, Helvetica, sans-serif;
            }
          }
        }
      }
    }
  }
}

.tag_money {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.tag_money > p {
  font-weight: 500;
  font-size: 14px;
  padding: 1px 10px;
  border-radius: 5px;
  border: 1px solid #3699ff;
  color: #0b3763;
  cursor: pointer;
  margin: 0 8px 8px 0;
  transition: 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tag_money > p:hover {
  color: #181c32;
}

.training_schedule {
  background-color: #fff;
  padding: 25px;

  .ant-card {
    border: none !important;
    box-shadow: none !important;

    .ant-card-cover {
      margin-bottom: 16px !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }

  .ant-card-meta-title {
    margin-bottom: 0 !important;
    white-space: inherit !important;

    .title_training {
      color: #071437;
      transition: color 0.2s ease;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
    .title_training:hover {
      color: #3e97ff;
      transition: color 0.2s ease;
    }
  }

  .des_training {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #78829d;
      font-weight: 500;
      font-size: 14px;
      margin: 13px 0;
      cursor: auto;
    }
    .create {
      font-size: 14px;
      font-weight: 600;
      .creator {
        color: #4b5675;
      }
      .createAt {
        color: #99a1b7;
      }
    }
  }
}

.action_training {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.action_training > div {
  margin-right: 10px;
}
.action_training > div > p {
  font-weight: 400;
  margin-bottom: 5px;
}
.oanffmafnsafa {
  width: 100%;
  margin-top: 10px;
}

.oanffmafnsafa > img {
  width: 100%;
  max-height: 250px;
}

.eijhqwfhsf {
  display: flex;
  align-items: center;
  margin: 25px 0;
  cursor: pointer;
}
.eijhqwfhsf > i {
  margin-right: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.eijhqwfhsf > p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.eqmmqwmeqneqwewq {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: auto;
}

.action_agency {
  display: flex;
}

.action_agency > div {
  margin: 0 5px;
  padding: 3px 6px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.action_agency > div:hover {
  background-color: #a1a1a1;
}

.tab_history {
  display: flex;
  justify-content: center;
}

.form_bank {
  margin-right: auto;
  margin-left: auto;
  padding: 0 0 50px 0;
  height: 100vh;
}

.form_bank > p {
  font-weight: 600;
  margin: 35px 0;
}
.info_agency {
  background-color: #efefef;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.info_agency > p {
  font-size: 14px;
  font-weight: 600;
}

.info_agency > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_agency > div > div > p {
  margin: 5px 0;
  font-size: 12px;
  font-weight: 600;
}

.info_agency > div > div > img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.ant-table-wrapper .ant-spin-container .ant-table table .ant-table-thead tr th {
  background: #e6f8fd;
  text-align: center;
  border: 1px solid #e9eef7;
  color: #000;
  font-weight: 700;
}
.ant-form-item-feedback-icon-error {
  display: flex;
}
.ant-table-wrapper .ant-spin-container .ant-table table .ant-table-tbody tr td {
  border: 1px solid #e9eef7;
  text-align: center;
}

.modal_add_config {
  .ant-modal-title {
    display: flex;
    width: 100%;
    span {
      font-size: 18px;
      color: #fff;
    }
  }
}

.customFormItem {
  margin-bottom: 5px;
}

.details_schedule {
  padding: 25px;
  min-height: 100vh;
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    font-size: 1.35em;
    color: #000;
    font-weight: 350;
  }
  p {
    color: #000;
    font-weight: 350;
  }
}

.controls_schedule {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 80px;
  margin-bottom: 20px;
  color: #000 !important;
}

.controls_schedule > i {
  font-size: 22px;
  font-weight: 600;
  margin-right: 5px;
}
.controls_schedule > p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.schedule_title_details > h1 {
  font-size: 24px;
  font-weight: 600;
}

.schedule_title_details {
  margin-bottom: 20px;
}

.schedule_time_details {
  margin-bottom: 20px;
}
.schedule_time_details > i {
  font-size: 18px;
  font-weight: 500;
  margin-right: 8px;
}
.schedule_time_details > span {
  font-size: 14px;
  font-weight: 500;
}

.schedule_content_details {
  color: #000 !important;
}

.content_schedule {
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.demo-loadmore-list {
  .title_list {
    color: #4b5675;
    cursor: pointer;
    font-weight: 600;
  }

  .item_list {
    cursor: pointer;
    padding: 12px;
    .item_cover {
      width: 100%;
    }
  }

  .item_list:hover {
    background-color: #f1f1f1;
  }
}

.ant-card-actions {
  margin-top: 10px !important;
}

.accordion.accordion-light-borderless .card {
  border-top: 0;
}

.modal_excel {
  max-height: 700px;
  overflow-x: scroll;
}
