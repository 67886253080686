.customSelect {
    .css-yk16xz-control {
        border: 1px solid #e4e6ef;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid #69b3ff;
        }
    }
}

.customSelectError {
    .css-yk16xz-control {
        border: 1px solid red;
    }
}

.css-1okebmr-indicatorSeparator {
    display: none;
}
