@import "./mixin.scss";
@import "./lib.scss";

.error-text {
    color: red;
    margin-top: 8px;
}

.table-col-isactive {
    padding: 6.5px 13px;
    border-radius: 4px;
    color: #ffffff;
}
.table-active {
    @extend .table-col-isactive;
    background-color: #1890ff;
}

.table-inactive {
    @extend .table-col-isactive;
    background-color: #ff4d4f;
}

.table-row-selected {
    cursor: pointer;

    &:hover {
        background-color: #fafafa;
    }
}

.btn-OK {
    color: #ffffff;
    background-color: #1890ff;
    padding: 8px 15px;
    cursor: pointer;
}

.mb-mb-5 {
    margin-bottom: 0px !important;
}

@include mb-down {
    .mb-offset-3 {
        width: 100%;
    }
    .mb-kt-section__content {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .mb-mb-10 {
        margin-bottom: 10px !important;
    }
    .mb-datepicker-41 {
        height: 41.59px;
    }
}
